var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex py-4 px-4 list-filter"},[_c('dialog-form-input-wrapper',{staticClass:"flex-grow-1"},[_c('v-text-field',{staticClass:"rounded-0 pt-0",attrs:{"background-color":"#F7F7F7","solo":"","flat":"","label":_vm.$t('components.integrations.chooseAccounts.search'),"single-line":"","filled":"","clearable":"","type":"search","elevation":"0","hide-details":"","color":"#686868","light":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.accountMapperSearch),callback:function ($$v) {_vm.accountMapperSearch=$$v},expression:"accountMapperSearch"}})],1),_c('v-spacer'),(!_vm.readOnly && _vm.allowStandardAccount)?_c('div',{staticClass:"d-flex flex-row align-center"},[_vm._v(" Map by: "),_c('dialog-form-select-input',{staticClass:"ml-4",staticStyle:{"max-width":"250px"},attrs:{"hideDetails":"","disabled":_vm.integrationSystem === 'manual',"items":[
          {
            text: 'Standard accounts',
            value: 0,
          },
          {
            text: 'GHG categories',
            value: 1,
          },
        ]},model:{value:(_vm.categoryModeProxy),callback:function ($$v) {_vm.categoryModeProxy=$$v},expression:"categoryModeProxy"}})],1):_vm._e()],1),_c('v-list',{staticClass:"category-account-mapper pt-0",attrs:{"tile":"","dense":""}},_vm._l((_vm.filteredAccounts),function(accountGroup,aindex){return _c('v-list-group',{key:aindex,staticClass:"category-groups",attrs:{"value":_vm.readOnly ? true : null,"append-icon":"mdi-menu-down","hidden":(_vm.readOnly &&
          _vm.readOnlyAccounts(accountGroup.accountNumbers).length === 0) ||
        accountGroup.hidden},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{staticClass:"d-inline",domProps:{"innerHTML":_vm._s(_vm.highlightText(accountGroup.name, _vm.accountMapperSearch))}}),_c('v-chip',{staticClass:"pa-1 ml-2 align-center justify-center",staticStyle:{"height":"23px","min-width":"23px"},attrs:{"color":_vm.countInGroup(accountGroup) > 0 ? '#F25D3B' : '#F7F7F7',"text-color":_vm.countInGroup(accountGroup) > 0 ? '#fff' : 'B4B4B4',"small":""}},[_vm._v(_vm._s(_vm.countInGroup(accountGroup)))])],1)],1)]},proxy:true}],null,true)},[(!_vm.readOnly)?_c('div',{staticClass:"list-header"},[_c('p',{staticClass:"sync"},[_vm._v(" "+_vm._s(_vm.$t("components.onboarding.accountMapper.sync"))+" ")]),_c('p',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t("components.onboarding.accountMapper.name"))+" ")]),_c('p',{staticClass:"syncTo"},[_vm._v(" "+_vm._s(_vm.$t("components.onboarding.accountMapper.syncTo"))+" ")])]):_c('div',{staticClass:"list-header"},[_c('p',{staticClass:"sync flex-grow-1"},[_vm._v(" "+_vm._s(_vm.$t("components.onboarding.accountMapper.nameOfCategory"))+" ")]),_c('p',{staticClass:"syncTo text-right"},[_vm._v(" "+_vm._s(_vm.$t("components.onboarding.accountMapper.suggestedMapping"))+" ")])]),_vm._l((_vm.readOnly
          ? _vm.readOnlyAccounts(accountGroup.accountNumbers)
          : accountGroup.accountNumbers),function(account,anindex){return [(!account.hidden)?_c('v-list-item',{key:anindex,staticClass:"account-number"},[(!_vm.readOnly)?_c('toggle-button',{staticClass:"mr-8",attrs:{"value":_vm.syncAccounts[account.id]},on:{"change":(val) => _vm.updateSyncLines(account, val)}}):_vm._e(),_c('v-list-item-title',{staticClass:"account-mapping-line",class:_vm.syncAccounts[account.accountId] ? 'sync-account' : ''},[_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.highlightText(
                  `${account.number} ${account.name}`,
                  _vm.accountMapperSearch
                )
              )}}),(_vm.autoMappedAccounts[account.id] && !_vm.readOnly)?_c('v-btn',{staticClass:"d-flex flex-row align-center rounded px-2 py-3 red--text mx-2",staticStyle:{"background-color":"#fdece7","pointer-events":"none"},attrs:{"x-small":"","color":"#F25D3B","text":"","elevation":"0"}},[_c('AIRobot',{staticClass:"mr-1"}),_vm._v(" "+_vm._s(_vm.$t("components.onboarding.accountMapper.autoSuggestion"))+" ")],1):_vm._e()],1),(_vm.readOnly)?_c('div',[_c('ArrowRightIcon',{staticClass:"arrow-icon"})],1):_vm._e(),(_vm.readOnly)?_c('div',{staticClass:"read-only-category d-flex flex-row justify-end align-center"},[_vm._v(" "+_vm._s(_vm.selectedCategoryForAccount(account)?.name)+" "),_c('ScopeBadge',{staticClass:"ml-4",attrs:{"scope":_vm.selectedCategoryForAccount(account)?.scope?.value?.split(
                  ' '
                )[1]}})],1):(
              (account.system === 'economic' &&
                account.accountType === 'totalFrom') ||
              (account.system === 'dynamics365' &&
                account.accountType === 'Total') ||
              (account.system === 'uniconta' && account.accountType === 'Sum')
            )?_c('div',{staticClass:"d-flex flex-row justify-center align-center no-activity-label"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("components.onboarding.accountMapper.sumAccount"))+" ")])],1):(_vm.syncAccounts[account.id])?_c('SelectGHGCategory',{attrs:{"items":_vm.categories,"valueKey":_vm.categoryMode === 1 ? 'id' : 'standardAccountId',"initialValue":_vm.selectedCategoryForAccount(account)},on:{"change":(val) =>
                _vm.categoryMode == 1
                  ? _vm.selectedGhgForAccount(account, val)
                  : _vm.selectedStandardAccountsForAccount(account, val)}}):(false /* account.balance === 0 */)?_c('div',{staticClass:"d-flex flex-row justify-center align-center no-activity-label"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("components.onboarding.accountMapper.noActivity"))+" ")])],1):_vm._e()],1):_vm._e()]})],2)}),1),(!_vm.hasAnyReadOnlyAccounts && _vm.readOnly)?_c('div',{staticClass:"pa-8 text-center font-weight-light text-subtitle-1 grey--text"},[_vm._v(" "+_vm._s(_vm.$t("components.onboarding.accountMapper.autoSuggestionWasNotPossible"))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }